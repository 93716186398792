<template>
  <a-row class=" mt-3 mb-3 pb-4 pe-2" :align="'middle'">
    <a-col class="me-5">
      <img src="@/assets/images/undraw_certificate.svg" width="90" alt="certified">
    </a-col>
    <a-col>
      <small class="fw-bolder fs-6">
        {{ certificate.name }}
<!--        <a href="#" class="text-primary" @click.prevent="openCertificateModal" v-if="editable">-->
<!--          <EditOutlined />-->
<!--        </a>-->
      </small>
      <div class="mt-2 mb-2">
        <small class="fw-bold me-2"> Provider:</small>
        <small class="text-muted">{{ certificate.provider }}</small>
      </div>
      <div class="mt-2 mb-2">
        <small class="fw-bold me-2">Issued:</small>
        <small class=" text-muted">{{moment(certificate.issued_date).format('MMM YYYY')}}</small>
      </div>
      <div class="mt-2 mb-2">
        <small class="fw-bold me-2">Expire Date:</small>
        <small class=" text-muted" v-if="certificate.expire_date">{{ moment(certificate.expire_date).format('MMM YYYY') }}</small>
        <small class=" text-muted" v-else>No Expiration Date</small>
      </div>
      <div class="mt-2 mb-2">
        <small class="fw-bold me-2">Certification ID:</small>
        <small class=" text-muted">{{ certificate.certificate_id }}</small>
      </div>
    </a-col>
  </a-row>
</template>

<script>
// import {EditOutlined} from "@ant-design/icons-vue";
import {inject, ref} from 'vue'
export default {
  name: "CertificateComponent",
  components: {
    // EditOutlined,
  },
  props: {
    certificate: {
      required: true,
      type: Object
    },
  },
  setup(props) {
    const moment = inject('moment')
    const hover = ref(false)
    const emitter = inject('emitter');
    const openCertificateModal = () => {
      emitter.emit('openCertificateModal', props.certificate)
    }
    return {
      hover,
      openCertificateModal,
      moment
    }
  }
}
</script>

<style scoped>

</style>