<template>
<a-card>
  <a-row :gutter="5">
    <a-col :span="3">
      <a-avatar shape="square" :size="{md: 64, lg: 64, xl: 64, xxl: 64}" v-if="comment.user.profile.image" :src="comment.user.profile.image" />
      <a-avatar shape="square" :size="{md: 64, lg: 64, xl: 64, xxl: 64}" v-else>
        <template #icon>
          <UserOutlined />
        </template>
      </a-avatar>
    </a-col>
    <a-col :span="21">
      <a-row :justify="'space-between'">
        <a-col>
          <div class="fw-bold">{{ comment.user.name }}</div>
          <small class="text-muted">Posted {{ moment(comment.created_at).fromNow() }}</small>
        </a-col>
      </a-row>

      <a-typography-paragraph
          class="my-3"
          :ellipsis="ellipsis ? { rows: 3, expandable: true, symbol: 'more' } : false"
          :content="comment.comment"
      />
    </a-col>
  </a-row>
</a-card>
</template>

<script>
import {inject, ref} from "vue";
import {
  // MoreOutlined,
  // EditOutlined,
  // FlagOutlined,
  UserOutlined} from '@ant-design/icons-vue'
import {useStore} from "vuex";

export default {
  name: "ReportCommentComponent",
  components: {
    // MoreOutlined,
    // EditOutlined,
    // FlagOutlined,
    UserOutlined
  },
  props: {
    comment: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const moment = inject('moment');
    const ellipsis = ref(true);
    const store = useStore()
    const authedUser = store.state.authedUser;
    const emitter = inject('emitter');

    const openReportDrawer = () => {
      emitter.emit('openReportDrawer', {id: props.comment.id, type: 'comment'});
    }

    return {
      moment,
      ellipsis,
      authedUser,
      openReportDrawer
    }
  }
}
</script>

<style scoped>

</style>