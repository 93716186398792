import gql from "graphql-tag";

export const ReportsGql = gql`
    query reports($limit:Int!,$page:Int!){
        reports(limit:$limit,page:$page){
            data{
                id
                reason
                reportable {
                    __typename
                    ... on Job {
                        id
                        title
                        overview
                        location
                        created_at
                        profile{
                            id
                            title
                            location
                            image
                            profile_slug
                            user {
                                id
                                name
                                email
                            }
                        }
                        specialty{
                            id
                            name
                        }
                    }
                    ... on ProfileReview {
                        id
                        rate
                        review
                        user{
                            id
                            name
                            email
                        }
                    }
                    ... on Post {
                        id
                        post
                        file {
                            file_url
                            file_type
                        }
                        user {
                            id
                            name
                            email
                            profile{
                                id
                                title
                                image
                            }
                        }
                        is_liked
                        privacy
                        file_id
                        created_at
                    }
                    ... on Comment {
                        id
                        comment
                        user {
                            id
                            name
                            email
                            profile{
                                id
                                title
                                image
                            }
                        }
                        created_at
                    }
                }
                user {
                    id
                    name
                    email
                }
            }
            total
        }
    }
`