import gql from "graphql-tag";

export const LoginGql = gql`
    mutation adminLogin($email: String!, $password: String!){
        adminLogin(email:$email, password:$password){
            access_token
            token_type
            admin {
                id
                name
                email
            }
        }
    }
`