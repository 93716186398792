<template>
  <UsersIndex title="Users & Clients" dataType="profiles" />
</template>

<script>
import UsersIndex from "@/views/users";

export default {
  name: "UsersPage",
  components: {UsersIndex}
}
</script>

<style scoped>

</style>