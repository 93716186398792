<template>
<a-row :justify="'center'" class="min-vh-100">
  <a-col :span="6" class="d-flex align-items-center">
    <div class="w-100">
      <a-card class="w-100">
        <div class="text-center">
          <img src="@/assets/images/dr.png" width="150" alt="doctors-gulf">
          <h4 class="mt-2">Login into your account</h4>
        </div>
        <a-form layout="vertical" :model="data" @finish="submitForm">
          <a-form-item label="Email" :required="true" :rules="[{ required: true, message: 'Please input your email!' }]">
            <a-input v-model:value="data.email" placeholder="Please Enter Your Email" />
          </a-form-item>
          <a-form-item label="Password" :required="true">
            <a-input-password v-model:value="data.password" placeholder="Please Enter Your Email" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" block html-type="submit">Login</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
  </a-col>
</a-row>
</template>

<script>
import {reactive} from 'vue';
import {LoginGql} from "@/gqls/login-gql";
import {useMutation} from '@vue/apollo-composable';
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {message} from "ant-design-vue";
export default {
  name: "LoginPage",
  setup() {
    const store = useStore();
    const router = useRouter()
    const data = reactive({
      email: '',
      password: ''
    })
    const { mutate, onDone, onError } = useMutation(LoginGql, {
      clientId: 'auth'
    })

    onDone((res) => {
      localStorage.setItem('authed_user', JSON.stringify(res.data.adminLogin))
      store.commit('setAuthedUser', res.data.adminLogin)
      router.push({name: 'dashboard-page'})
    })
    onError(() => {
      message.error('Email or password is not matched')
    })
    const submitForm = () => {
      mutate({
        email: data.email,
        password: data.password,
      })
    }
    return {
      data,
      mutate,
      submitForm
    }
  }
}
</script>

<style scoped>

</style>