<template>
<a-row>
  <a-col :span="22" :offset="1">
    <a-row :gutter="[10, 10]">
      <a-col :span="24" class="text-end">
        <a-space>
<!--          <a-dropdown :trigger="['click']">-->
<!--            <a class="ant-dropdown-link" @click.prevent>-->
<!--              <a-button type="default">Take Action <DownOutlined /></a-button>-->
<!--            </a>-->
<!--            <template #overlay>-->
<!--              <a-menu>-->
<!--                -->
<!--                <a-menu-item key="1">-->
<!--                  Deactivate Post User-->
<!--                </a-menu-item>-->
<!--&lt;!&ndash;                <a-menu-divider />&ndash;&gt;-->
<!--&lt;!&ndash;                <a-menu-item key="3">3rd menu item</a-menu-item>&ndash;&gt;-->
<!--              </a-menu>-->
<!--            </template>-->
<!--          </a-dropdown>-->

          <a-popconfirm
              title="Are you sure delete this post?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="deleteReportPost(report.id, false)"
          >
            <a-button type="default">Remove Post</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="Are you sure ignore this report?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="deleteReportPost(report.id)"
          >
            <a-button danger>Ignore</a-button>
          </a-popconfirm>
        </a-space>
      </a-col>
      <a-col :span="24">
        <a-descriptions title="Who made this report?">
          <a-descriptions-item>
            <template #label>
              <strong>Name</strong>
            </template>
            {{report.user.name}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label>
              <strong>Email</strong>
            </template>
            {{report.user.email}}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="24">
        <a-descriptions title="Reported post?" v-if="report.reportable.__typename == 'Job'" layout="vertical">
          <a-descriptions-item>
            <template #label>
              <strong>Title</strong>
            </template>
            {{report.reportable.title}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label>
              <strong>Overview</strong>
            </template>
            {{report.reportable.overview}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label>
              <strong>Location</strong>
            </template>
            {{report.reportable.location}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label>
              <strong>Specialty</strong>
            </template>
            {{report.reportable.specialty.name}}
          </a-descriptions-item>

          <a-descriptions-item>
            <template #label>
              <strong>User Email</strong>
            </template>
            {{report.reportable.profile.user.email}}
          </a-descriptions-item>

          <a-descriptions-item>
            <template #label>
              <strong>User Name</strong>
            </template>
            {{report.reportable.profile.user.name}}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions v-if="report.reportable.__typename == 'ProfileReview'" layout="vertical">
          <a-descriptions-item :span="24">
            <template #label>
              <strong>Review</strong>
            </template>
            {{report.reportable.review}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label>
              <strong>Rate</strong>
            </template>
            {{report.reportable.rate}}
          </a-descriptions-item>

          <a-descriptions-item>
            <template #label>
              <strong>User Email</strong>
            </template>
            {{report.reportable.user.email}}
          </a-descriptions-item>

          <a-descriptions-item>
            <template #label>
              <strong>User Name</strong>
            </template>
            {{report.reportable.user.name}}
          </a-descriptions-item>
        </a-descriptions>
        <div v-if="report.reportable.__typename == 'Post'">
          <ReportPostComponent :post="report.reportable" />
        </div>
        <div v-if="report.reportable.__typename == 'Comment'">
          <ReportCommentComponent :comment="report.reportable" />
        </div>
      </a-col>
<!--      <a-col :span="24">-->
<!--        <a-descriptions title="Reported post?" />-->
<!--      </a-col>-->
    </a-row>
  </a-col>
</a-row>
</template>

<script>
// import {DownOutlined} from "@ant-design/icons-vue";

import {useMutation} from "@vue/apollo-composable";
import {DeleteReportPostMutationGql} from "@/gqls/delete-report-post-mutation-gql";
import {message} from "ant-design-vue";
import {inject} from "vue";
import ReportPostComponent from "@/components/ReportPostComponent.vue";
import ReportCommentComponent from "@/components/ReportCommentComponent.vue";

export default {
  name: "ReportExpandView",
  components: {ReportCommentComponent, ReportPostComponent},
  // components: {DownOutlined},
  props: {
    report: {
      required: true,
      type: Object
    }
  },
  setup() {
    const {mutate} = useMutation(DeleteReportPostMutationGql);
    const emitter = inject('emitter');

    const deleteReportPost = (id, ignore = true) => {
      mutate({
        id,
        ignore
      }).then(() => {
        message.success('Report has operated successfully.')
        emitter.emit('refetchReportsData')
      });
    }
    return {
      deleteReportPost
    }
  }
}
</script>

<style scoped>

</style>