<template>
  <div class="pb-5">
    <!--  <a-page-header class="bg-white" title="Users & Clients"/>-->
    <a-page-header title="Reports" class="bg-white">
<!--      <template #footer>-->
<!--        <a-tabs class="header-tabs" size="small" @change="changeProfileType">-->
<!--          <a-tab-pane key="all" tab="All" />-->
<!--          <a-tab-pane key="medical_center" tab="Medical Centers" />-->
<!--          <a-tab-pane key="user" tab="Practitioner" />-->
<!--        </a-tabs>-->
<!--      </template>-->
    </a-page-header>
    <div class="container pt-5">
      <a-row justify="end" align="center" class="mb-3">
        <a-col>
          <a-space>
            <a-range-picker v-model:value="filter_date" />
            <a-button type="default">
              <!--            <template #icon>-->
              <!--              <reload-outlined />-->
              <!--            </template>-->
              Reload
            </a-button>
            <a-button type="primary">
              <!--            <template #icon>-->
              <!--              <export-outlined />-->
              <!--            </template>-->
              Export
            </a-button>
            <!--          <a-button type="text">-->
            <!--            <template #icon>-->
            <!--              <fullscreen-outlined />-->
            <!--            </template>-->
            <!--          </a-button>-->
          </a-space>
        </a-col>
      </a-row>
      <div class="text-center" v-if="!ready">
        <a-spin />
      </div>
      <a-space class="w-100" direction="vertical" v-else>
        <a-table :columns="columns" :data-source="result.reports.data" :rowKey="rowKey" :transformCellText="transformCellText"  :emptyText="'N/A'" :loading="loading" :pagination="false">
          <template #headerCell="{ column }">
        <span>
          {{ column.name }}
        </span>
          </template>
          <template #bodyCell="{ column, record }">
          <span v-if="column.key == 'report_type'">
            {{ record.reportable.__typename }}
          </span>
            <span v-else-if="column.key == 'created_at'">
              {{dayjs(record.created_at).format('MMMM D, YYYY h:mm A')}}
            </span>
          </template>
          <template #expandedRowRender="{ record }">
            <div>
              <ReportExpandView :key="record.id" :report="record"/>
            </div>
          </template>
        </a-table>
        <a-row justify="end">
          <a-col>
            <a-pagination v-model:current="current_page" :total="total" v-model:defaultPageSize="limit" v-model:pageSize="limit" @change="pageChange" show-less-items />
          </a-col>
        </a-row>
      </a-space>
    </div>
  </div>
</template>

<script>
import {ref, inject, watch, reactive} from 'vue'
import {useQuery} from "@vue/apollo-composable";
import {ReportsGql} from "@/gqls/reports-gql";
import ReportExpandView from "@/views/ReportExpandView";
export default {
  name: "ReportsPage",
  components: {ReportExpandView},
  setup() {
    const filter_date = ref(null);
    const dayjs = inject('dayjs');
    const columns = ref([
      {
        name: 'Report Type',
        dataIndex: 'report_type',
        key: 'report_type',
      },
      {
        name: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
      },
      {
        name: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
      },
    ]);

    const current_page = ref(1)
    const limit = ref(50)
    const total = ref(0);
    const ready = ref(false);
    const queryVariable = reactive({
      limit: limit,
      page: current_page,
    })

    const {result, loading, refetch} = useQuery(ReportsGql, queryVariable);
    const emitter = inject('emitter')
    const transformCellText = ({ text }) => {
      return text != '' ? text : 'N/A'
    }
    const rowKey = (record) => {
      return record.id
    }

    emitter.on('refetchReportsData', () => {
      refetch()
    })

    const pageChange = (page) => {
      current_page.value = page
      refetch()
    }

    watch(result, (result) => {
      total.value = result.reports.total
      ready.value = true
    })
    watch(filter_date, (value) => {
      if (value) {
        queryVariable.from = dayjs(value[0]).format('YYYY/MM/DD')
        queryVariable.to = dayjs(value[1]).format('YYYY/MM/DD')
      } else {
        delete queryVariable.from
        delete queryVariable.to
      }
    })

    return {
      ready,
      filter_date,
      result,
      loading,
      dayjs,
      columns,
      transformCellText,
      rowKey,
      total,
      pageChange,
      current_page,
      limit
    }
  }
}
</script>

<style scoped>

</style>