import {createApp, h, provide} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'bootstrap/dist/css/bootstrap.css'
import '@/style/main.less'
import { ApolloClients } from '@vue/apollo-composable'
import DefaultApolloClient from "./ApolloClients/DefaultApolloClient";
import AuthApolloClient from "./ApolloClients/AuthApolloClient";
import dayjs from "dayjs";
import mitt from "mitt";
import moment from 'moment';

createApp({
        setup () {
            provide(ApolloClients, {
                default: DefaultApolloClient,
                auth: AuthApolloClient
            })
            provide('dayjs', dayjs)
            provide('emitter', mitt())
            provide('moment', moment)
        },
        render: () => h(App)
    }).use(Antd)
    .use(store)
    .use(router)
    .mount('#app')
