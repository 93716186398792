import { createStore } from 'vuex'

export default createStore({
  state: {
    authedUser: {}
  },
  getters: {
  },
  mutations: {
    setAuthedUser(state, data) {
      state.authedUser = data
    },
  },
  actions: {
  },
  modules: {
  }
})
