<template>
  <div>
    <a-row :gutter="10">
      <a-col :span="22" :offset="1">
        <a-row>
          <a-col :span="24">
            <a-row justify="space-between" align="center">
              <a-col>
                <a-space>
                  <a-avatar :size="80" :src="profile.image" v-if="profile.image" />
                  <a-avatar :size="80" v-else>
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
<!--                  <a-avatar :size="80" src="https://joeschmoe.io/api/v1/random" />-->
                  <div>
                    <a-typography-title :level="4">{{ profile.user.name }}</a-typography-title>
                    <span>{{ profile.title }}</span>
                  </div>
                </a-space>
              </a-col>
              <a-col>
                <a-popconfirm
                    title="Are you sure deactivate this user?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="userActivity(profile.user.id, !profile.user.is_active)"
                    v-if="profile.is_approved"
                >
                  <a-button danger v-if="profile.user.is_active">
                    Deactivate
                  </a-button>
                  <a-button type="primary" v-else>
                    Activate
                  </a-button>
                </a-popconfirm>
                <div v-else>
                  <a-space>
                    <a-popconfirm
                        title="Are you sure accept this user?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="profileApproval(profile.id, true)"
                    >
                      <a-button :disabled="loading">
                        Accept Request
                      </a-button>
                    </a-popconfirm>
                    <a-popconfirm
                        title="Are you sure reject this user?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="profileApproval(profile.id, false)"
                    >
                      <a-button danger :disabled="loading">
                        Reject Request
                      </a-button>
                    </a-popconfirm>
                  </a-space>
                </div>

              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="22" offset="1" class="mt-4">
        <a-descriptions layout="vertical">
          <a-descriptions-item>
            <template #label>
              <strong>Bio</strong>
            </template>
            <a-typography-paragraph
                :ellipsis="ellipsis ? { rows: 2, expandable: true, symbol: 'more' } : false"
                :content="profile.bio"
            />
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="Profile Details" layout="vertical">
          <a-descriptions-item>
            <template #label>
              <strong>Location</strong>
            </template>
            {{ profile.location ?? '-' }}
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label>
              <strong>Created at</strong>
            </template>
            {{ dayjs(profile.created_at).format('MMMM D, YYYY h:mm A') }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="Languages">
          <a-descriptions-item v-for="(item, index) in profile.languages" :key="index">
            <template #label>
              <strong>
                {{item.title}}
              </strong>
            </template>
            {{ item.value }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="Specialties">
          <a-descriptions-item :span="24">
<!--            <template #label>-->
<!--              <strong>Specialties</strong>-->
<!--            </template>-->
            <div v-if="profile.specialties.length > 0">
              <span class="badge text-bg-primary m-1" v-for="(item, index) in profile.specialties" :key="index">
                {{ item.name }}
              </span>
            </div>
            <div v-else>
              <span>
                -
              </span>
            </div>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="Education">
          <a-descriptions-item v-for="(item, index) in profile.education" :key="index">
            <template #label>
              <strong>
                {{item.school}}
              </strong>
            </template>
            {{ dayjs(item.start_date).format('MMMM, YYYY') }} - {{ dayjs(item.end_date).format('MMMM, YYYY') }}
<!--            {{ item.start_date }} - {{item.end_date}}-->
          </a-descriptions-item>
          <a-descriptions-item v-if="profile.education.length == 0">
            <span>-</span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="Other Details">
          <a-descriptions-item>
            <template #label>
              <strong>
                Certificates Details
              </strong>
            </template>
<!--            <a-button type="link" @click="openCertificatesModal">-->
<!--              <small>View Certificates</small>-->
<!--            </a-button>-->
            <a href="#" @click.prevent="openCertificatesModal">
              <small>View Certificates</small>
            </a>
          </a-descriptions-item>
          <a-descriptions-item v-if="profile.is_approved">
            <template #label>
              <strong>
                Employment History
              </strong>
            </template>
            <a href="">
              <small>View History</small>
            </a>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <ViewCertificatesModal />
  </div>
</template>

<script>
import {UserOutlined} from "@ant-design/icons-vue";
import {inject, ref} from "vue";
import {useMutation} from "@vue/apollo-composable";
import {UserActivityGql} from "@/gqls/user-activity-gql";
import {ProfileApprovalGql} from "@/gqls/profile-approval-gql";
import {message} from "ant-design-vue";
import ViewCertificatesModal from "@/components/ViewCertificatesModal";
// import User_employment_history_view from "@/views/users/user_employment_history_view";

export default {
  name: "user_view_expand",
  props: {
    profile: {
      required: true,
      type: Object
    }
  },
  components: {
    ViewCertificatesModal,
    // User_employment_history_view,
    UserOutlined
  },
  setup(props) {
    const emitter = inject('emitter')
    const ellipsis = ref(true)
    const dayjs = inject("dayjs")
    const { mutate } = useMutation(UserActivityGql);
    const {mutate: profileApprovalMutate, loading} = useMutation(ProfileApprovalGql)
    const userActivity = (id, is_active) => {
      mutate({
        id,
        is_active
      }).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    }
    const profileApproval = (profile_id, is_approved) => {
      profileApprovalMutate({
        profile_id,
        is_approved
      }).then(() => {
        message.success('User approval update successfully!')
      })
    }
    const openCertificatesModal = () => {
      emitter.emit('openCertificatesModal', props.profile.certificates);
    }
    return {
      ellipsis,
      dayjs,
      userActivity,
      profileApproval,
      loading,
      openCertificatesModal
    }
  }
}
</script>

<style scoped>

</style>