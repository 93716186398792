import gql from "graphql-tag";

export const ProfilesGql = gql`
    query ($limit:Int, $page:Int, $is_approved:Boolean, $profile_type:String, $from:String, $to:String){
        profiles(limit:$limit, page:$page, is_approved:$is_approved, profile_type:$profile_type, from:$from, to:$to){
            data {
                id
                title
                bio
                profile_type
                location
                phone
                website
                founded
                registration_number
                languages
                rate
                is_approved
                education {
                    school
                    start_date
                    end_date
                }
                created_at
                specialties {
                    id
                    name
                }
                user {
                    id
                    name
                    email
                    is_active
                }
                certificates{
                    id
                    name
                    provider
                    expire_date
                    issued_date
                    certificate_id
                }
            }
            total
        }
    }
`