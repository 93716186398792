<template>
<div class="pb-5">
  <!--  <a-page-header class="bg-white" title="Users & Clients"/>-->
  <a-page-header title="Settings" class="bg-white">
          <template #footer>
            <a-tabs class="header-tabs" size="small" v-model:active-key="activeKey">
<!--              <a-tab-pane key="general" tab="General" />-->
              <a-tab-pane key="payment" tab="Payment Setting" />
              <a-tab-pane key="google_analytics" tab="Google Analytics" />
            </a-tabs>
          </template>
  </a-page-header>
  <div class="container pt-5">
<!--    <General_view v-if="activeKey == 'general'" />-->
    <Payment_view v-if="activeKey == 'payment'" />
    <Analytics_view v-if="activeKey == 'google_analytics'" />
  </div>
</div>
</template>

<script>
import {ref} from 'vue'
import Payment_view from "@/views/settings/payment_view";
import Analytics_view from "@/views/settings/analytics_view";
export default {
  name: "SettingPage",
  components: {Analytics_view, Payment_view},
  setup() {
    const activeKey = ref('payment');
    return {
      activeKey
    }
  }
}
</script>

<style scoped>

</style>