<template>
  <a-menu
      theme="light"
  >
    <a-menu-item key="dashboard" @click="$router.push({name: 'dashboard-page'})">
      <template #icon>
        <DashboardOutlined />
      </template>
      Dashboard
    </a-menu-item>
    <a-menu-item key="users" @click="$router.push({name: 'users-page'})">
      <template #icon>
        <UserOutlined />
      </template>
      Users & Clients
    </a-menu-item>
    <a-menu-item key="requests" @click="$router.push({name: 'registration-requests'})">
      <template #icon>
        <UnorderedListOutlined />
      </template>
      Registration Requests
    </a-menu-item>
    <a-menu-item key="admins" @click="$router.push({name: 'admins'})">
      <template #icon>
        <LockOutlined />
      </template>
      Admins
    </a-menu-item>
    <a-menu-item key="reports" @click="$router.push({name: 'reports'})">
      <template #icon>
        <IdcardOutlined />
      </template>
      Reports
    </a-menu-item>
    <a-menu-item key="settings" @click="$router.push({name: 'settings'})">
      <template #icon>
        <SettingOutlined />
      </template>
      Settings
    </a-menu-item>
  </a-menu>
</template>

<script>
import {DashboardOutlined, UserOutlined, LockOutlined, SettingOutlined, UnorderedListOutlined, IdcardOutlined} from "@ant-design/icons-vue";

export default {
  name: "SideMenuComponent",
  components: {
    DashboardOutlined,
    UserOutlined,
    LockOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    IdcardOutlined
  }
}
</script>

<style scoped>

</style>