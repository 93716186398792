<template>
<UsersIndex title="Registrations Requests" dataType="requests" />
</template>

<script>
import UsersIndex from "@/views/users";
export default {
  name: "RequestsPage",
  components: {UsersIndex}
}
</script>

<style scoped>

</style>