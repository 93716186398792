<template>
  <div class="pb-5">
    <div class="container pt-5 text-center" v-if="!ready">
      <a-spin />
    </div>
    <div class="container pt-5" v-else>
      <a-spin :spinning="loading">
        <a-row justify="end" class="my-3">
          <a-col>
            <a-range-picker v-model:value="filter_date" />
          </a-col>
        </a-row>
        <a-card>
          <a-row :justify="'space-between'">
            <a-col>
              <a-statistic title="All Users" :value="result.dashboardQuery.all_users" />
            </a-col>
            <a-col>
              <a-statistic title="Registration Requests" :value="result.dashboardQuery.registration_requests" />
            </a-col>
            <a-col>
              <a-statistic title="Active Users" :value="result.dashboardQuery.active_users" />
            </a-col>
            <a-col>
              <a-statistic title="Passive Users" :value="result.dashboardQuery.passive_users" />
            </a-col>
          </a-row>
        </a-card>
        <a-row :gutter="20" class="mt-5">
          <a-col :span="12">
            <a-card>
              <a-typography-title :level="4">Users</a-typography-title>
              <BarChart :labels="usersGraphLabels" :data="usersGraphData" data-label="Users" />
            </a-card>
          </a-col>

          <a-col :span="12">
            <a-card>
              <a-typography-title :level="4">Jobs</a-typography-title>
              <BarChart :labels="jobsGraphLabels" :data="jobsGraphData" data-label="Jobs" />
            </a-card>
          </a-col>
        </a-row>
      </a-spin>
    </div>
  </div>
</template>

<script>
import BarChart from "@/charts/BarChart";
import {
  inject,
  ref,
  watch
} from 'vue';
import {useQuery} from "@vue/apollo-composable";
import {DashboardGql} from "@/gqls/dashboard-gql";
export default {
  name: "DashboardPage",
  components: {BarChart},
  setup() {
    const ready = ref(false)
    const dayjs = inject('dayjs')
    const usersGraphLabels = ref([])
    const usersGraphData = ref([])
    const jobsGraphLabels = ref([])
    const jobsGraphData = ref([])
    const filter_date = ref([
      dayjs().subtract(5, 'month'),
      dayjs(),
    ]);
    const queryVars = ref({
      "from": dayjs(filter_date.value[0]).format('D-M-YYYY'),
      "to": dayjs(filter_date.value[1]).format('D-M-YYYY')
    });
    const {result, loading, onResult} = useQuery(DashboardGql, queryVars);

    onResult(() => {
      ready.value = true

      usersGraphLabels.value = [];
      usersGraphData.value = [];
      jobsGraphLabels.value = [];
      jobsGraphData.value = [];

      Object.keys(result.value.dashboardQuery.graphs.users_graph).forEach(key => {
        usersGraphLabels.value.push(key);
        usersGraphData.value.push(result.value.dashboardQuery.graphs.users_graph[key])
      });

      Object.keys(result.value.dashboardQuery.graphs.jobs_graph).forEach(key => {
        jobsGraphLabels.value.push(key);
        jobsGraphData.value.push(result.value.dashboardQuery.graphs.jobs_graph[key])
      });
    })

    watch(filter_date, (value) => {
      queryVars.value = {
        from: dayjs(value[0]).format('D-M-YYYY'),
        to: dayjs(value[1]).format('D-M-YYYY')
      }
    })

    return {
      filter_date,
      result,
      dayjs,
      loading,
      ready,
      usersGraphLabels,
      usersGraphData,
      jobsGraphLabels,
      jobsGraphData
    }
  }
}
</script>

<style scoped>

</style>