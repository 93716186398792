<template>
  <a-layout class="min-vh-100">
    <a-layout-sider theme="light" class="border-end">
      <div class="text-center mt-2">
        <a class="navbar-brand text-primary fw-bolder fs-3" href="#">
          <img src="@/assets/images/dr.png" width="80" alt="doctors-gulf">
        </a>
      </div>
      <SideMenuComponent />
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="bg-white border-bottom">
        <NavbarComponent />
      </a-layout-header>
      <a-layout-content>
<!--        <div class="container pt-5 pb-5">-->
          <router-view />
<!--        </div>-->
      </a-layout-content>
    </a-layout>
<!--    <a-layout-footer>Footer</a-layout-footer>-->
  </a-layout>
</template>

<script>
import SideMenuComponent from "@/components/SideMenuComponent";
import NavbarComponent from "@/components/NavbarComponent";
export default {
  name: "master-template",
  components: {NavbarComponent, SideMenuComponent}
}
</script>

<style scoped>

</style>