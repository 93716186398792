<template>
  <div>
    <a-row :gutter="10">
      <a-col :span="22" :offset="1">
        <a-row>
          <a-col :span="24">
            <a-row justify="space-between" align="center">
              <a-col>
                <a-space>
                  <a-avatar :size="80" :src="profile.image" v-if="profile.image" />
                  <a-avatar :size="80" v-else>
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
<!--                  <a-avatar :size="80" src="https://joeschmoe.io/api/v1/random" />-->
                  <div>
                    <a-typography-title :level="4">{{ profile.user.name }}</a-typography-title>
<!--                    <span>Senior Backend</span>-->
                  </div>
                </a-space>
              </a-col>
              <a-col>
                <a-popconfirm
                    title="Are you sure deactivate this user?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="userActivity(profile.user.id, !profile.user.is_active)"
                    v-if="profile.is_approved"
                >
                  <a-button danger v-if="profile.user.is_active">
                    Deactivate
                  </a-button>
                  <a-button type="primary" v-else>
                    Activate
                  </a-button>
                </a-popconfirm>
                <div v-else>
                  <a-space>
                    <a-popconfirm
                        title="Are you sure accept this user?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="profileApproval(profile.id, true)"
                    >
                      <a-button :disabled="loading">
                        Accept Request
                      </a-button>
                    </a-popconfirm>
                    <a-popconfirm
                        title="Are you sure reject this user?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="profileApproval(profile.id, false)"
                    >
                      <a-button danger :disabled="loading">
                        Reject Request
                      </a-button>
                    </a-popconfirm>
                  </a-space>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="22" offset="1" class="mt-4">
        <a-row>
          <a-col :span="22" offset="1">
            <a-descriptions layout="vertical">
              <a-descriptions-item>
                <template #label>
                  <strong>Overview</strong>
                </template>
                <a-typography-paragraph
                    :ellipsis="ellipsis ? { rows: 2, expandable: true, symbol: 'more' } : false"
                    :content="profile.bio"
                />
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :span="22" :offset="1" class="pt-4">
            <a-descriptions title="Profile Details" layout="vertical">
              <a-descriptions-item>
                <template #label>
                  <strong>Location</strong>
                </template>
                {{ profile.location ?? '-' }}
              </a-descriptions-item>
              <a-descriptions-item >
                <template #label>
                  <strong>Registration Number</strong>
                </template>
                {{ profile.registration_number ?? '-' }}
              </a-descriptions-item>
              <a-descriptions-item>
                <template #label>
                  <strong>Founded</strong>
                </template>
                {{ profile.founded ?? '-' }}
              </a-descriptions-item>
              <a-descriptions-item>
                <template #label>
                  <strong>Address</strong>
                </template>
                {{ profile.address ?? '-' }}
              </a-descriptions-item>
              <a-descriptions-item>
                <template #label>
                  <strong>Rate</strong>
                </template>
                <div>
                  <a-rate :value="parseFloat(profile.rate)" allow-half disabled />
                  <p>
                    <a href="">
                      <small>View Reviews</small>
                    </a>
                  </p>
                </div>
              </a-descriptions-item>
              <a-descriptions-item>
                <template #label>
                  <strong>Created at</strong>
                </template>
                {{ dayjs(profile.created_at).format('MMMM D, YYYY h:mm A') }}
              </a-descriptions-item>
              <a-descriptions-item :span="2">
                <template #label>
                  <strong>Specialties</strong>
                </template>
                <div v-if="profile.specialties.length > 0">
                  <span class="badge text-bg-primary m-1" v-for="(item, index) in profile.specialties" :key="index">
                    {{ item.name }}
                  </span>
                </div>
                <div v-else>
                  <span>
                    -
                  </span>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {ref, inject} from 'vue'
import {UserOutlined} from "@ant-design/icons-vue";
import {useMutation} from "@vue/apollo-composable";
import {UserActivityGql} from "@/gqls/user-activity-gql";
import {message} from "ant-design-vue";
import {ProfileApprovalGql} from "@/gqls/profile-approval-gql";

export default {
  name: "medical_user_view_expand",
  props: {
    profile: {
      required: true,
      type: Object
    }
  },
  components: {
    UserOutlined
  },
  setup() {
    const ellipsis = ref(true)
    const dayjs = inject("dayjs")
    const { mutate } = useMutation(UserActivityGql);
    const userActivity = (id, is_active) => {
      mutate({
        id,
        is_active
      }).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    }
    const {mutate: profileApprovalMutate, loading} = useMutation(ProfileApprovalGql)
    const profileApproval = (profile_id, is_approved) => {
      profileApprovalMutate({
        profile_id,
        is_approved
      }).then(() => {
        message.success('User approval update successfully!')
      })
    }
    return {
      ellipsis,
      dayjs,
      userActivity,
      mutate,
      profileApproval,
      loading
    }
  }
}
</script>

<style scoped>

</style>