<template>
  <div class="pb-5">
    <!--  <a-page-header class="bg-white" title="Users & Clients"/>-->
    <a-page-header :title="title" class="bg-white">
      <template #footer>
        <a-tabs class="header-tabs" size="small" @change="changeProfileType">
          <a-tab-pane key="all" tab="All" />
          <a-tab-pane key="medical_center" tab="Medical Centers" />
          <a-tab-pane key="user" tab="Practitioner" />
        </a-tabs>
      </template>
    </a-page-header>
    <div class="container pt-5">
      <a-row justify="end" align="center" class="mb-3">
        <a-col>
          <a-space>
            <a-range-picker v-model:value="filter_date" />
            <a-button type="default">
              <!--            <template #icon>-->
              <!--              <reload-outlined />-->
              <!--            </template>-->
              Reload
            </a-button>
            <a-button type="primary">
              <!--            <template #icon>-->
              <!--              <export-outlined />-->
              <!--            </template>-->
              Export
            </a-button>
            <!--          <a-button type="text">-->
            <!--            <template #icon>-->
            <!--              <fullscreen-outlined />-->
            <!--            </template>-->
            <!--          </a-button>-->
          </a-space>
        </a-col>
      </a-row>
      <div class="text-center" v-if="!ready">
        <a-spin />
      </div>
      <a-space class="w-100" direction="vertical" v-else>
        <a-table :columns="columns" :data-source="result.profiles.data" :rowKey="rowKey" :transformCellText="transformCellText"  :emptyText="'N/A'" :loading="loading" :pagination="false">
          <template #headerCell="{ column }">
        <span>
          {{ column.name }}
        </span>
          </template>
          <template #bodyCell="{ column, record }">
          <span v-if="column.key == 'name'">
            {{ record.user.name }}
          </span>
            <span v-else-if="column.key == 'created_at'">
              {{dayjs(record.created_at).format('MMMM D, YYYY h:mm A')}}
            </span>
          </template>
          <template #expandedRowRender="{ record }">
            <div>
              <Medical_user_view_expand v-if="record.profile_type == 'medical_center'" :key="record.id" :profile="record"/>
              <User_view_expand v-else :profile="record" :key="record.id" />
            </div>
          </template>
        </a-table>
        <a-row justify="end">
          <a-col>
            <a-pagination v-model:current="current_page" :total="total" v-model:defaultPageSize="limit" v-model:pageSize="limit" @change="pageChange" show-less-items />
          </a-col>
        </a-row>
      </a-space>
    </div>
  </div>
</template>

<script>
import {inject, reactive, ref, watch} from 'vue'
import Medical_user_view_expand from "@/views/users/medical_user_view_expand";
import User_view_expand from "@/views/users/user_view_expand";
import {ProfilesGql} from "@/gqls/profiles-gql";
import {useQuery} from '@vue/apollo-composable';
import {useRoute} from 'vue-router'
export default {
  name: "UsersIndex",
  components: {
    User_view_expand,
    Medical_user_view_expand,
  },
  props: {
    title: {
      required: true,
      type: String
    },
    dataType: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const route = useRoute()
    const dayjs = inject('dayjs')
    const filter_date = ref();
    const columns = ref([
      {
        name: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        name: 'Profile Title',
        dataIndex: 'title',
        key: 'title',
      },
      {
        name: 'User Type',
        dataIndex: 'profile_type',
        key: 'profile_type',
      },
      {
        name: 'Location',
        dataIndex: 'location',
        key: 'location',
      },
      {
        name: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        name: 'Founded',
        dataIndex: 'founded',
        key: 'founded',
      },
      {
        name: 'Registration Number',
        dataIndex: 'registration_number',
        key: 'registration_number',
      },
      {
        name: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
      },
    ]);
    const current_page = ref(1)
    const limit = ref(50)
    const total = ref(0);
    const ready = ref(false);
    const queryVariable = reactive({
      limit: limit,
      page: current_page,
      is_approved: props.dataType == 'profiles' ? true : false,
    })

    const {result, loading, refetch} = useQuery(ProfilesGql, queryVariable);
    const transformCellText = ({ text }) => {
      return text != '' ? text : 'N/A'
    }
    const rowKey = (record) => {
      return record.id
    }

    const pageChange = (page) => {
      current_page.value = page
      refetch()
    }

    const changeProfileType = (e) => {
      current_page.value = 1
      limit.value = 50
      total.value = 0
      if (e == 'all') {
        delete queryVariable.profile_type
      } else {
        queryVariable.profile_type = e
      }
    }

    watch(result, (result) => {
      total.value = result.profiles.total
      ready.value = true
    })
    watch(filter_date, (value) => {
      if (value) {
        queryVariable.from = dayjs(value[0]).format('YYYY/MM/DD')
        queryVariable.to = dayjs(value[1]).format('YYYY/MM/DD')
      } else {
        delete queryVariable.from
        delete queryVariable.to
      }
    })
    return {
      ready,
      columns,
      result,
      loading,
      filter_date,
      transformCellText,
      rowKey,
      current_page,
      limit,
      total,
      pageChange,
      changeProfileType,
      dayjs,
      route,
      props
    }
  }
}
</script>

<style scoped>

</style>