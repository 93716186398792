import gql from "graphql-tag";

export const AdminsGql = gql`
    query admins($limit:Int!,$page:Int!){
        admins(limit:$limit,page:$page){
            data{
                id
                name
                email
                is_active
#                roles {
#                    id
#                    name
#                }
            }
        }
    }
`