<template>
  <a-modal v-model:visible="visible" title="View Certificates">
    <CertificateComponent v-for="(item, index) of certificates" :certificate="item" :key="index" />

    <template #footer>
      <a-row :justify="'end'">
        <a-col>
          <a-button @click="visible = false">Close</a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import {inject, ref} from "vue";
import CertificateComponent from "@/components/CertificateComponent";

export default {
  name: "ViewCertificatesModal",
  components: {CertificateComponent},

  setup() {
    const visible = ref(false);
    const emitter = inject('emitter')
    const certificates = ref([]);
    emitter.on('openCertificatesModal', (data) => {
      visible.value = true;
      certificates.value = data;
    });
    return {
      visible,
      certificates
    }
  }
}
</script>

<style scoped>

</style>