import gql from "graphql-tag";

export const UpdateAdminMutationGql = gql`
    mutation updateAdmin($id:Int!, $name:String!, $email:String!, $roles:ArrayScalar!, $is_active:Boolean!){
        updateAdmin(id:$id,name:$name,email:$email,roles:$roles,is_active:$is_active){
            id
            name
            email
            is_active
            roles{
                id
            }
        }
    }
`