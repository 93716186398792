import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from "@/pages/LoginPage";
import MasterTemplate from "@/templates/master"
import DashboardPage from "@/pages/DashboardPage";
import UsersPage from "@/pages/UsersPage";
import getAuthData from "../helpers/handel-auth-data";
import store from '../store/index'
import RequestsPage from "@/pages/RequestsPage";
import AdminsPage from "@/pages/AdminsPage";
import SettingPage from "@/pages/SettingPage";
import ReportsPage from "@/pages/ReportsPage";

const routes = [
  {
    path: '/login',
    name: 'login-page',
    component: LoginPage
  },
  {
    path: '/',
    name: 'admin-panel-master',
    component: MasterTemplate,
    beforeEnter: (to, from, next) => checkIfUserAuthed(to, from, next),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard-page',
        component: DashboardPage,
      },
      {
        path: '/users-and-clients',
        name: 'users-page',
        component: UsersPage,
      },
      {
        path: '/registration-requests',
        name: 'registration-requests',
        component: RequestsPage,
      },
      {
        path: '/admins',
        name: 'admins',
        component: AdminsPage,
      },
      {
        path: '/reports',
        name: 'reports',
        component: ReportsPage,
      },
      {
        path: '/settings',
        name: 'settings',
        component: SettingPage,
      },
    ]
  }
]

/*eslint-disable */
function checkIfUserAuthed(to, from, next) {
  const authedData = getAuthData()
  if (authedData) {
    store.state.authedUser = authedData
    next()
  }
  next({name: 'login-page'})
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
