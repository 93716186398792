<template>
  <div class="pb-5">
    <!--  <a-page-header class="bg-white" title="Users & Clients"/>-->
    <a-page-header title="Admins" class="bg-white">
<!--      <template #footer>-->
<!--        <a-tabs class="header-tabs" size="small">-->
<!--          <a-tab-pane key="all" tab="All" />-->
<!--          <a-tab-pane key="medical_center" tab="Medical Centers" />-->
<!--          <a-tab-pane key="user" tab="Practitioner" />-->
<!--        </a-tabs>-->
<!--      </template>-->
    </a-page-header>
    <div class="container pt-5">
      <a-row justify="end" align="center" class="mb-3">
        <a-col>
          <a-space>
            <a-range-picker v-model:value="filter_date" />
            <a-button type="default">
              <!--            <template #icon>-->
              <!--              <reload-outlined />-->
              <!--            </template>-->
              Reload
            </a-button>
            <a-button type="primary">
              <!--            <template #icon>-->
              <!--              <export-outlined />-->
              <!--            </template>-->
              Export
            </a-button>
            <!--          <a-button type="text">-->
            <!--            <template #icon>-->
            <!--              <fullscreen-outlined />-->
            <!--            </template>-->
            <!--          </a-button>-->
          </a-space>
        </a-col>
      </a-row>
      <div class="text-center" v-if="!ready">
        <a-spin />
      </div>
      <a-space class="w-100" direction="vertical" v-else>
        <a-table :columns="columns" :data-source="result.admins.data" :rowKey="rowKey" :transformCellText="transformCellText"  :emptyText="'N/A'" :loading="loading" :pagination="false">
          <template #headerCell="{ column }">
            <span>
              {{ column.name }}
            </span>
          </template>
          <template #bodyCell="{ column, record }">
            <span v-if="column.key == 'name'">
              <a-badge :color="record.is_active ? 'green' : 'red'" /> {{ record.name }}
            </span>
            <span v-else-if="column.key == 'created_at'">
              {{dayjs(record.created_at).format('MMMM D, YYYY h:mm A')}}
            </span>
            <span v-else-if="column.key == 'roles'">
              <div v-if="record.roles.length > 0">
                <span v-for="(role, index) in record.roles" :key="index">
                  {{(index + 1) == record.roles.length ? role.name : role.name + ', '}}
                </span>
              </div>
            </span>
            <span v-else-if="column.key == 'actions'">
              <a-space size="middle">
                <a-tooltip>
                  <template #title>Edit</template>
                  <EditOutlined class="cursor-pointer" @click="showDrawer(record)" />
                </a-tooltip>
                <a-tooltip>
                  <template #title>Delete</template>
                  <DeleteOutlined class="cursor-pointer" />
                </a-tooltip>
              </a-space>
            </span>
          </template>
<!--          <template #expandedRowRender="{ record }">-->
<!--            <div>-->
<!--              <Medical_user_view_expand v-if="record.profile_type == 'medical_center'" :key="record.id" :profile="record"/>-->
<!--              <User_view_expand v-else :profile="record" :key="record.id" />-->
<!--            </div>-->
<!--          </template>-->
        </a-table>
        <a-row justify="end">
          <a-col>
            <a-pagination v-model:current="current_page" :total="total" :defaultPageSize="limit" @change="pageChange" show-less-items />
          </a-col>
        </a-row>
      </a-space>
    </div>
    <AdminDrawer />
  </div>
</template>

<script>
import {useRoute} from "vue-router/dist/vue-router";
import {inject, reactive, ref, watch} from "vue";
import {useQuery} from "@vue/apollo-composable";
import {AdminsGql} from "@/gqls/admins-gql";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import AdminDrawer from "@/components/AdminDrawer";

export default {
  name: "AdminsPage",
  components: {
    AdminDrawer,
    EditOutlined,
    DeleteOutlined
  },
  setup() {
    const emitter = inject('emitter');
    const route = useRoute()
    const dayjs = inject('dayjs')
    const filter_date = ref();
    const columns = ref([
      {
        name: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        name: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      // {
      //   name: 'Roles',
      //   dataIndex: 'roles',
      //   key: 'roles',
      // },
      {
        name: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        name: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
      },
    ]);
    const current_page = ref(1)
    const limit = ref(50)
    const total = ref(0);
    const ready = ref(false);
    const queryVariable = reactive({
      limit: limit,
      page: current_page,
    })
    const {result, loading, refetch} = useQuery(AdminsGql, queryVariable);

    const transformCellText = ({ text }) => {
      return text != '' ? text : 'N/A'
    }
    const rowKey = (record) => {
      return record.id
    }

    const pageChange = (page) => {
      current_page.value = page
      refetch()
    }

    const showDrawer = (admin) => {
      emitter.emit('openAdminDrawer', admin)
    }

    watch(result, (result) => {
      total.value = result.admins.total
      ready.value = true
    })
    return {
      ready,
      columns,
      result,
      loading,
      filter_date,
      transformCellText,
      rowKey,
      current_page,
      limit,
      total,
      pageChange,
      dayjs,
      route,
      showDrawer
    }

  }
}
</script>

<style scoped>

</style>