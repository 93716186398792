<template>
<a-row :justify="'end'">
  <a-col>
    <a-dropdown :trigger="['click']">
      <a class="ant-dropdown-link" @click.prevent>
        {{ store.state.authedUser.admin.name }}
        <DownOutlined />
      </a>
      <template #overlay>
        <a-menu>
<!--          <a-menu-item key="0">-->
<!--            <a href="http://www.alipay.com/">1st menu item</a>-->
<!--          </a-menu-item>-->
<!--          <a-menu-item key="1">-->
<!--            <a href="http://www.taobao.com/">2nd menu item</a>-->
<!--          </a-menu-item>-->
<!--          <a-menu-divider />-->
          <a-menu-item key="3" @click="logout">Logout</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </a-col>
</a-row>
</template>

<script>
import {useStore} from "vuex";
import {DownOutlined} from "@ant-design/icons-vue";
import {useRouter} from "vue-router";

export default {
  name: "NavbarComponent",
  components: [
    DownOutlined
  ],
  setup() {
    const store = useStore();
    const router = useRouter()
    const logout = () => {
      localStorage.clear()
      store.state.authedUser = null
      router.push({name: 'login-page'})
    };
    return {
      store,
      logout
    }
  }
}
</script>

<style scoped>

</style>