<template>
  <a-row>
    <a-col :span="10" :offset="6">
      <a-card>
        <a-form
            name="basic"
            class="w-100"
            autocomplete="off"
            layout="vertical"
            @finish="onFinish"
        >
          <a-form-item label="Public Key">
            <a-input v-model:value="paymentSettings.public_key" />
          </a-form-item>
          <a-form-item label="Secret Key">
            <a-input v-model:value="paymentSettings.secret_key" />
          </a-form-item>
          <a-form-item label="Subscription amount">
            <a-input v-model:value="paymentSettings.amount" />
          </a-form-item>
          <a-form-item>
            <a-button html-type="submit" type="primary">Save</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import {ref} from 'vue'
export default {
  name: "payment_view",
  setup() {
    const paymentSettings = ref({
      amount: 0,
      public_key: null,
      secret_key: null
    });

    const onFinish = () => {

    }

    return {
      paymentSettings,
      onFinish
    }
  }
}
</script>

<style scoped>

</style>