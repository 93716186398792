<template>
  <a-drawer
      v-model:visible="visible"
      title="Admin"
      placement="right"
      @afterVisibleChange="onVisibleChange"
  >
    <a-form
        :model="admin"
        autocomplete="off"
        layout="vertical"
        @finish="saveData"
    >
      <a-form-item
          label="Name"
          name="name"
          :rules="[{ required: true, message: 'Please input admin name!' }]"
      >
        <a-input v-model:value="admin.name" />
      </a-form-item>

      <a-form-item
          label="Email"
          name="email"
          :rules="[{ required: true, message: 'Please input admin email!' }]"
      >
        <a-input v-model:value="admin.email" />
      </a-form-item>

<!--      <a-form-item-->
<!--          label="Role"-->
<!--          name="role"-->
<!--      >-->
<!--        <a-select-->
<!--            v-model:value="selectedRoles"-->
<!--            mode="tags"-->
<!--            style="width: 100%"-->
<!--            placeholder="Select Admin Role"-->
<!--            :loading="loading">-->
<!--          <a-select-option v-for="(item, index) in roles" :value="item.id" :key="index">{{item.name}}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-item>-->

      <a-row :justify="'space-between'" :align="'center'">
        <a-col>
          Active:
        </a-col>
        <a-col>
          <a-switch v-model:checked="admin.is_active" />
        </a-col>
      </a-row>

      <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
        <a-button type="primary" html-type="submit" id="submitAdminButton" />
      </a-form-item>
    </a-form>

    <template #footer>
      <div class="text-end">
        <a-space :size="5">
          <a-button @click="visible = false">Cancel</a-button>
          <a-button type="primary" @click="submitForm">Submit</a-button>
        </a-space>
      </div>
    </template>
  </a-drawer>
</template>

<script>
import {inject, reactive, ref/*, watch*/} from 'vue';
import {useMutation /*, useQuery*/} from "@vue/apollo-composable";
// import {RolesGql} from "@/gqls/roles-gql";
import {UpdateAdminMutationGql} from "@/gqls/update-admin-mutation-gql";
export default {
  name: "AdminDrawer",
  setup() {
    const selectedRoles = ref([])
    const roles = ref([]);
    const emitter = inject('emitter')
    const visible = ref(false);
    let admin = reactive({
      id: null,
      name: '',
      email: '',
      is_active: true,
      // role: {},
    });

    // const {result, loading} = useQuery(RolesGql);
    const { mutate: updateAdmin } = useMutation(UpdateAdminMutationGql)

    const showDrawer = () => {
      visible.value = true;
    };

    const submitForm = () => {
      document.getElementById('submitAdminButton').click();
    }

    const onVisibleChange = (visible) => {
      if (!visible) {
        admin = Object.assign(admin, {
          id: null,
          name: '',
          email: '',
          is_active: true,
          role: '',
        })
        selectedRoles.value = []
      }
    }

    const saveData = () => {
      updateAdmin({
        "id": admin.id,
        "name": admin.name,
        "email": admin.email,
        'is_active': admin.is_active,
        "roles": selectedRoles.value
      }).then(() => {
        visible.value = false
      })
    }

    emitter.on('openAdminDrawer', (data) => {
      Object.assign(admin, data)
      showDrawer();
      if(data.roles.length > 0) {
        selectedRoles.value = data.roles.map(x => {
          return x.id
        })
      }
    });

    // watch(result, (data) => {
    //   roles.value = data.roles
    // })

    return {
      visible,
      admin,
      submitForm,
      // result,
      // loading,
      roles,
      selectedRoles,
      onVisibleChange,
      saveData
    };
  },
}
</script>

<style scoped>

</style>