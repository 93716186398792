<template>
  <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    labels: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    dataLabel: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: [
          {
            label: this.dataLabel,
            backgroundColor: '#6C63FF',
            borderColor: '#6C63FF',
            fill: false,
            data: this.data
          }
        ]
      },
      chartOptions: {
        responsive: true
      }
    }
  }
}
</script>